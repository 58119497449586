import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Protocol from '../views/Protocol.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/protocol',
        name: 'Protocol',
        component: Protocol,
        meta: {
            requiresAuth: false
        }
    },
    // {
    //     path: '/about',
    //     name: 'Home',
    //     component: Home,
    //     meta: {
    //         requiresAuth: false
    //     }
    // },
]
const router = new VueRouter({
    linkActiveClass: 'active',
    routes
})
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        if (to.meta.requiresAuth && !userId) {
            next({
                path: '/login'
            })
        } else {
            next()
        }
    }
})
export default router