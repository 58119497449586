<template>
  <div style="background-color: white;width: 100%;height: 100%">
    <div style="width: 1200px;margin: 0 auto;text-align: center">
      <p class="title">隐私条款</p>
      <div style="width: 100%;text-align: left">
        <p class="title1">小卡笔记隐私政策</p>
        <p class="title2">导言部分</p>
        <p class="content">尊敬的用户，小卡笔记APP（以下简称“小卡笔记”）是由上海无远教育科技有限公司（以下简称“无远公司”或“我们”）为您（以下亦称“用户”）提供的一款记录产品，深知个人隐私信息对您的重要性，因此我们非常重视保护您的个人隐私信息，并会尽最大努力保护您个人隐私信息及数据的安全可靠，为此我们将会对本《小卡笔记隐私政策》（以下也称“本政策”）中的内容以高度审慎的义务对待和处理。本政策仅适用于小卡笔记收集和存储的用户信息和数据。</p>
        <p class="content" style="margin-top: 10px">本政策与您所使用的我们的产品与/或服务息息相关，我们将按照本政策的约定处理和保护您的个人隐私信息因此我们希望您能够仔细阅读、充分理解本政策的全文，并在需要时，按照本政策的指引，作出您认为适当的选择。本政策之中涉及的相关技术词汇，我们尽量以简明扼要的表述向您解释，以便于您理解。我们在此善意的提醒您，本政策中与您的权益（可能）存在重大关系的条款，我们已采用加粗字体来特别提醒您，请您留意重点查阅。</p>
        <p class="content" style="margin-top: 10px">您应当在仔细阅读、充分理解本隐私后使用我们的产品与/或服务，如果您不同意本政策的内容，将可能导致我们的产品与/或服务无法正常运行，或者无法达到我们拟达到的服务效果，您应立即停止访问/使用我们的产品与/或服务。您使用或继续使用我们提供的产品与/或服务的行为，都表示您充分理解和同意本隐私政策（包括后续更新版本）的全部内容。</p>
        <p class="content" style="margin-top: 10px">另外，您需要承诺在您开始使用本产品时已具备相匹配的民事行为能力，否则需要您的监护人同意您使用本产品并同意相关的服务条款和隐私政策。</p>
        <p class="content" style="margin-top: 10px">若您在阅读本隐私政策过程中有任何疑惑或其他相关事宜的，您可向小卡笔记客服咨询。</p>

        <p class="content" style="margin-top: 50px">本隐私政策将帮助您了解以下内容</p>
        <p class="content" style="margin: 10px 0 ">一． 本政策的适用范围</p>
        <p class="content" style="margin: 10px 0">二． 个人隐私信息的涵义</p>
        <p class="content" style="margin: 10px 0">三． 我们如何收集和使用您的个人隐私信息</p>
        <p class="content" style="margin: 10px 0">四． 保留政策和信息存储</p>
        <p class="content" style="margin: 10px 0">五． 优先顺序</p>
        <p class="content" style="margin: 10px 0">六． 联系我们</p>
        <p class="content" style="margin: 10px 0">七． 更新说明</p>
        <p class="content" style="margin: 10px 0">八． 其他</p>


        <p class="title1" style="margin-top: 50px">一． 本政策的适用范围、相关词语的含义</p>
        <p class="content">本政策为我们所有产品与/或服务统一适用的通用内容，当您成为注册登录用户并使用我们的任何产品与/或服务时，本政策即适用，无论该产品与/或服务是否单独设置了隐私条款。但请您注意，本政策不适用于以下情况：
          1.通过我们的产品与/或服务而接入的由第三方直接提供的第三方服务（包括任何第三方网站）收集的信息；
          2.通过在我们的产品与/或服务中进行广告服务的其他公司和机构所收集的信息；
          3.我们的产品与/或服务可能会包含或链接至第三方提供的信息与/或第三方服务（包括任何第三方应用、网站、产品、服务等），这些信息与/或服务由第三方负责运营，您使用该等信息与/或服务与我们无关。我们在此善意的提醒您，您在使用第三方信息与/或服务时，应留意和仔细阅读第三方向您展示的相关用户协议和隐私协议，并妥善保管和谨慎提供您的个人隐私信息。本政策仅适用于我们所收集的您的个人隐私信息，并不适用于任何第三方对您的个人隐私信息的收集，以及任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方收集、储存和使用的您个人隐私信息的行为在法律允许的范围内亦不承担任何责任；
          4.其他非小卡笔记向您提供的产品与/或服务内容。</p>

        <p class="title1" style="margin-top: 50px">二． 个人隐私信息的涵义</p>
        <p class="content">本政策项下的个人隐私信息系指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括但不限于个人基本资料（包括个人姓名、生日、性别、住址、个人电话号码、手机号码、电子邮箱）、个人身份信息（包括身份证信息）、面部特征、网络身份标识信息（包括账户名、IP地址）、通讯录信息、个人上网记录（包括网站浏览记录、软件使用记录、点击记录）、个人常用设备信息（包括硬件型号、设备MAC地址、操作系统类型、软件列表、唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）。</p>


        <p class="title1" style="margin-top: 50px">三． 我们如何收集和使用您的个人隐私信息</p>
        <p class="content">您在使用我们的产品与/或服务时，我们需要/可能需要收集和使用您的一些个人隐私信息，我们收集和使用的您的个人隐私信息类型包括两种：第一种：我们产品与/或服务的核心业务功能所必需的信息：此类信息为产品与/或服务正常运行的必备信息，您须授权我们收集。如您拒绝提供，您将无法正常使用我们的产品与/或服务；第二种：附加业务功能可能需要收集的信息：此信息为非核心业务功能所需的信息，您可以选择是否授权我们收集。如您拒绝提供，将导致附加业务功能无法实现或无法达到我们拟达到的效果，但不影响您对核心业务功能的正常使用。
          另外，您充分理解并同意，我们在以下情况下收集、使用您的个人隐私信息无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：
          1.国家安全、国防安全有关的；
          2.与公共安全、公共卫生、重大公共利益有关的；
          3.与犯罪侦查、起诉、审判和判决执行等直接相关的；或根据法律法规的要求、行政机关或公检法等机关的要求的；
          4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
          5.所收集的信息是您自行向社会公开的或者是从合法公开的渠道（如合法的新闻报道、政府信息公开等渠道）中收集到的；
          6.根据与您签订和履行相关协议或其他书面文件所必需的；
          7.我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；
          8.对收集的个人隐私信息已经进行匿名化/去标识化处理的；
          9.为合法的新闻报道所必需的；
          10.其他法律法规等规定的情形。
          我们需要特别提醒您的是，我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，包括技术，这意味着我们可能会经常推出新的业务功能，可能需要收集新的个人隐私信息或变更个人隐私信息使用目的或方式。如果某一需要收集您的个人隐私信息的功能或产品/服务未能在本政策中予以说明的，我们会通过更新本政策、页面提示、弹窗、交互流程、网站公告等方式另行向您说明该信息收集的目的、内容、使用方式和范围，并为您提供自主选择同意的方式，且在征得您明示同意后收集。在此过程中，如果您有任何疑惑或相关事宜的，可以通过文末提供的方式联系我们，我们会尽快为您作出解答。
          具体业务功能场景包括：
          1.内容展示功能
          我们的产品与/或服务为您提供内容展示的过程中，我们会需要收集您的一些个人隐私信息，例如：设备型号、设备名称、设备唯一标识符、浏览器类型和设置、语言设置、操作系统和应用程序版本、登录IP地址、接入网络的方式、网络质量数据、移动网络信息（包括运营商名称）、产品版本号、日志信息（如操作日志、服务日志）等。我们收集这些个人隐私信息是为了向您提供我们最核心的内容展示，如您拒绝提供可能导致您无法使用我们的产品与服务。
          2.搜索功能
          当您使用搜索功能时，我们需要收集您的一些信息，例如：搜索的字词、浏览记录以及与它们互动的次数等。我们收集这些信息是为了向您提供您所需要的更好的体验，同时亦可以改进我们的产品和服务。
          3.注册功能
          当您希望注册成为小卡笔记用户时，您需要按照我们的指引完成一系列的注册程序，在您成为注册用户后，我们将为您提供专属于注册用户的产品与/或服务。在此过程中，您需要提供给我们一些单独或者结合识别您的用户身份的信息，例如：手机号码、验证码匹配结果等，并创建账号UID、密码。我们收集这些信息是用以完成注册程序、为您持续稳定提供注册（会员）服务，并保护您注册（会员）账号的安全。您应知悉，手机号码和验证码匹配结果属于您的个人敏感信息，我们收集该类信息是为了满足相关法律法规的要求，如您拒绝提供可能导致您无法使用我们的此功能，请您谨慎考虑后再提供。
          4. 保障账号安全功能
          我们需要收集您的一些信息来保障您使用我们的产品与/或服务时的账号与系统安全，并协助提升我们的产品与/服务的安全性和可靠性，以防产生任何危害用户、小卡笔记、社会的行为，例如：您的账号登录地、常用的设备信息、登录IP地址、产品版本号、语言模式、浏览记录、网络使用习惯、服务故障信息。我们会根据上述信息来综合判断您账号、账户及交易风险、进行身份验证、客户服务、检测及防范安全事件、诈骗监测、存档和备份用途，并依法采取必要的记录、审计、分析、处置措施，一旦我们检测出存在或疑似存在账号安全风险时，我们会使用相关信息进行安全验证与风险排除，确保我们向您提供的产品和服务的安全性，以用来保障您的权益不受侵害。同时，当发生账号或系统安全问题时，我们会收集这些信息来优化我们的产品和服务。</p>




        <p class="title1" style="margin-top: 50px">四． 保留政策和信息存储</p>
        <p class="content">1.存储地点：我们依照法律法规的规定，将您的个人隐私信息存储于中华人民共和国境内。目前我们暂时不存在跨境存储您的个人隐私信息或向境外提供个人隐私信息的场景。如需跨境存储或向境外提供个人隐私信息的，我们会单独向您明确告知（包括出境的目的、接收方、使用方式与范围、使用内容、安全保障措施、安全风险等）并再次征得您的授权同意，并严格要求接收方按照本政策以及其他相关要求来处理您的个人隐私信息。
          2.存储期限：我们在为提供我们的产品和服务之目的所必需且最短的期间内保留您的个人隐私信息，例如：当您使用我们的注册及会员功能时，我们需要收集您的手机号码，且在您提供后并在您使用该功能期间，我们需要持续为您保持您的手机号码，以向您正常提供该功能、保障您的账号和系统安全。在超出上述存储期限后，我们会对您的个人隐私信息进行删除或匿名化处理。但您注销账号的或法律法规另有规定的除外。
          3.保留政策：如您申请注销小卡笔记账号且已成功联系小卡笔记客服提交注销申请的，在此期间内，我们为您的该账号提供15天的保留期限。在15天内，为便于您申请恢复该账号，该账号下的相关信息我们暂时不会作删除或匿名化处理。如超过15天后，我们未收到您恢复账号的请求或者未出现任何其他不得注销事由的，该账号将被永久注销，该账号下的相关信息我们会作删除或匿名化处理。</p>


        <p class="title1" style="margin-top: 50px">五． 优先顺序</p>
        <p class="content">若本政策与其他任何相关文件存在不一致，如无特别指明，则以本政策为准；若本政策末包含（约定）的相关内容，则以其他相关文件中包含（约定）的为准。</p>


        <p class="title1" style="margin-top: 50px">六． 联系我们</p>
        <p class="content">若您对本政策有任何疑问、意见或建议，或者对我们收集使用或披露您的个人隐私信息有任何问题，请拨打我们的客服电话021 8018 6500与我们联系。</p>


        <p class="title1" style="margin-top: 50px">七． 更新说明</p>
        <p class="content">我们保留随时更新本政策的权利，当政策发生修改时，我们会告知/通知的方式使您了解变更后的隐私政策。如您继续使用我们的服务，即视为您同意我们的变更后的隐私政策，我们才会按照更新后的隐私政策收集、使用、存储您的个人隐私信息。</p>


        <p class="title1" style="margin-top: 50px">八． 其他</p>
        <p class="content">本政策的解释及争议解决均应适用中华人民共和国大陆地区法律。与本政策相关的任何纠纷，双方应协商友好解决；若不能协商解决，您同意将争议提交至无远公司住所地人民法院诉讼解决。
          本政策的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Protocol"
}
</script>

<style scoped>

.title {
  font-size: 30px;
  color: #333333;
  margin-top: 100px;
  font-family: "Microsoft YaHei";
  font-weight: bold;
}
.title1 {
  font-size: 20px;
  color: #333333;
  font-family: "Microsoft YaHei";
  font-weight: bold;
}
.title2 {
  font-size: 20px;
  color: #333333;
  font-family: "Microsoft YaHei";
  font-weight: normal;
}
.content {
  text-indent: 40px;
  line-height: 40px;
  font-size: 20px;
  color: #333333;
  font-family: "Microsoft YaHei";
  font-weight: normal;
}

</style>