<template>
  <div class="home_container">
    <div class="home_top">
      <div style="width: 1200px;margin: 0 auto;position: relative">
        <div style="position: relative;display: flex;flex-direction: row;align-items: center;margin-top: 40px">
          <div style="display: flex;flex-direction: row;align-items: center;">
            <img src="../assets/logo.png" style="width: 52px;height: 52px;">
            <p style="margin-left: 20px;color: #000000;font-size: 24px;font-weight: bold">小卡笔记</p>
          </div>
          <div style="position: absolute;right: 0;display: flex;flex-direction: row;align-items: center;">
            <p @click="goAbout"
               style="cursor: pointer;font-size: 24px;color: #333333;background-color: #E0F0EF;border-radius: 31px;padding: 19px 36px">
              关于小卡 </p>
            <p @click="goDown"
               style="cursor: pointer;margin-left: 45px;font-size: 24px;color: #333333;background-color: #E0F0EF;border-radius: 31px;padding: 19px 36px">
              下载小卡</p>
            <p @click="goProtocol"
               style="cursor: pointer;margin-left: 45px;font-size: 24px;color: #333333;background-color: #E0F0EF;border-radius: 31px;padding: 19px 36px">
              隐私条款</p>
          </div>
        </div>
        <div style="margin-top: 16.64vw">
          <img src="../assets/home_title.png" style="width: 710px;height: auto">
        </div>
      </div>
    </div>
    <div style="width: 100%;background-color: white;" id="about-card">
      <div class="note_content">
        <p style="color: #333333;font-weight: 800;font-size: 40px;font-family: 'PingFang SC'">小卡笔记是一款专注于<span
            style="color: #FFBD00;font-size: 44px">思绪管理</span>的笔记应用
        </p>
        <p style="color: #333333;font-weight: 800;font-size: 40px">将笔记内容限制在短小的卡片纸上</p>
        <p style="color: #333333;font-weight: 800;font-size: 40px">追求<span
            style="color: #FFBD00;font-size: 44px">简洁与效率</span>的结合</p>

        <div style="margin-top: 80px;display: flex;flex-direction: row;">
          <el-card style="padding: 30px 20px;">
            <img src="../assets/intro_card_1.png" style="width: 184px;height: 158px">
            <p class="intro_card_title">简洁卡片界面</p>
            <p class="intro_card_content">笔记界面只有一张卡片大小，<br>
              提示用户将思绪的精华记在其中，<br>没有其他资料的干扰。</p>
          </el-card>
          <el-card style="padding: 28px 20px;margin-left: 50px">
            <img src="../assets/intro_card_2.png" style="width: 170px;height: 169px">
            <p class="intro_card_title">检索重温方便</p>
            <p class="intro_card_content">带有标签分类和搜索功能，<br>
              方便重温和查阅过往的思考笔记。</p>
          </el-card>
          <el-card style="padding: 30px 20px;margin-left: 50px">
            <img src="../assets/intro_card_3.png" style="width: 201px;height: 138px">
            <p class="intro_card_title">加密云端储存</p>
            <p class="intro_card_content">所有信息均多重加密后在云端储存，<br>保障信息安全，方便多设备读取。</p>
          </el-card>
        </div>

      </div>
    </div>
    <div style="width: 100%;background-color: #F9F9F9;">
      <div class="note_content">
        <div style="display: flex;flex-direction: row;align-items: center">
          <img src="../assets/icon_creater.png" style="width: 97px;height: auto">
          <p style="margin-left: 36px;color: #333333;font-size: 40px;font-weight: bold;font-family: 'PingFang SC'">
            创作者说</p>
        </div>
        <div style="text-align: left">
          <p class="creater_intro">
            初版的小卡笔记是一款我给自己开发的思考笔记应用。现在自己想要用的各种工具应用，基本都选择自己给自己<br>
            定制开发了。小卡笔记也是这样一个过程中的产物。
          </p>
          <p class="creater_intro" style="margin-top: 15px">
            我用小卡笔记主要用来记录平时的随想、读书笔记还有灵感。在开发小卡笔记之前，说实在的没有太多满意的解<br>
            决方案。之前用了几年的某象笔记，转存和收藏资料为主，最后无论内容还是功能都越来越重，反而不适合快速<br>
            记录自己的想法；其他第三方的笔记应用没法根据自己的需要迭代功能；手机自带的便签不方便迁移，不适合长<br>
            期记录；纸质笔记本记起来不方便，更容易丢...
          </p>
          <p class="creater_intro" style="margin-top: 15px">
            现在使用小卡笔记已经一段时间了，看着上面的笔记越来越多，心理也很有成就感。身边也逐渐有亲人和朋友在<br>
            我的推荐下，开始使用起了小卡笔记。我想小卡笔记不仅是一款笔记应用，更应该成为一个帮助人进行思绪整理<br>
            的工具，更高效地重温思考、构建知识体系、在不同的思考之间建立新的连接...
          </p>
          <p class="creater_intro" style="margin-top: 15px">
            所以我期待有更多支持这个理念的朋友可以一起来使用小卡笔记，并且将你们的想法加入到迭代的计划中来。有<br>
            任何想法或意见，欢迎邮件至jason@reachable-edu.com
          </p>
        </div>

        <div style="position: absolute;bottom: 32px;right: 0px;z-index: -1">
          <img src="../assets/creater_maohao.png" style="width: 262px;height: auto">
        </div>
      </div>
    </div>
    <div style="width: 100%;background-color: white;">
      <div class="note_content">
        <div style="display: flex;flex-direction: row-reverse;align-items: center;">
          <img src="../assets/update_log.png" style="width: 88px;height: auto">
          <p style="margin-right: 36px;color: #333333;font-size: 40px;font-weight: bold;font-family: 'PingFang SC'">
            更新日志</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center">
          <div class="update_circle"/>
          <p class="update_log_text">初版 2021-12-07：系统文本优化，“中等”改为“中号”，“当前字体”改为“当前字号”</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-12-07：卡片布局优化</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-12-06：优化卡片标签，自动分析文本并建议添加</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-12-06：新增“特大”字号显示选项</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-12-05：新增反馈意见入口</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-12-05：卡片显示最新更新时间</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-10-11：新增卡片便签缓存；自定义界面字体大小显示；
            卡片详情界面显示重温卡片次数</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-09-22：小卡笔记运行稳定性优化</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-09-12：搜索功能操作逻辑优化</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-08-19：卡片列表显示改为倒序</p>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;margin-top: 25px">
          <div class="update_circle"/>
          <p class="update_log_text">更新 2021-08-18：实现应用基本功能</p>
        </div>
      </div>
    </div>

    <div class="download-bg" id="download-view">
      <div style="position: absolute;right: 18.8vw;top: 19vw;display: flex;flex-direction: column">
        <img src="../assets/download_logo.png" style="width: 30vw;height: auto">
        <canvas id="qrcode" style="width: 18vw;height: 18vw;margin-top: 15px"></canvas>
      </div>
    </div>
    <div style="width: 100%;height: 300px;background-color: white"/>
  </div>
</template>
<script>
import QRCode from 'qrcode'
import scroll from "../utils/scroll";

export default {
  name: 'Home',
  data() {
    return {}
  },
  mounted() {
    QRCode.toCanvas(document.getElementById('qrcode'), 'https://mainbucket.learningfirst.cn/mininote.apk', function (error) {
      if (error) console.error(error)
      console.log('success!');
    })
  },
  methods: {
    goProtocol(){
      this.$router.push('/protocol')
    },
    goAbout() {
      var about_card = document.getElementById("about-card")
      var offsetTop = about_card.offsetTop
      scroll(0, offsetTop)
      // window.scrollTo(0, offsetTop)
    },
    goDown() {
      var download_view = document.getElementById("download-view")
      var offsetTop = download_view.offsetTop
      scroll(0, offsetTop)
      // window.scrollTo(0, offsetTop)
    }
  }
}
</script>

<style scoped lang="scss">

.home_container {
  max-width: 1920px;
}

.download-bg {
  position: relative;
  background-image: url("../assets/download_bg.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-color: transparent;
  background-size: contain;
  width: 100%;
  height: 56vw;
}

.home_top {
  background-image: url("../assets/home_bg.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-color: transparent;
  background-size: contain;
  width: 100%;
  height: 56vw;
}

.intro_card_title {
  color: #030303;
  font-size: 30px;
  font-family: 'PingFang SC';
  font-weight: bold
}

.intro_card_content {
  text-align: left;
  color: #030303;
  font-size: 18px;
  font-family: "PingFang SC";
  font-weight: 300;
  line-height: 34px;
}

.creater_intro {
  color: #555555;
  font-size: 24px;
  font-family: "PingFang SC";
  line-height: 50px;
  font-weight: 300;
}

.note_content {
  z-index: 1;
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  padding-top: 146px;
  padding-bottom: 146px
}

.update_circle {
  width: 17px;
  height: 17px;
  border: 1px solid #666666;
  border-radius: 50%;
}

.update_log_text {
  margin: 0 0 0 42px;
  color: #555555;
  font-size: 24px;
  font-family: "PingFang SC";
}
</style>